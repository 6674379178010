import { graphql, useStaticQuery } from "gatsby"

function useProfileImage() {
  const {
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(graphql`
    query ProfileImage {
      file(name: { eq: "profile" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return fluid
}

export default useProfileImage
