import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row } from "../components/grid"
import PostClassic from "../components/post/classic"
import useProfileImage from "../hooks/useProfileImage"

const IndexPage = ({
  pageContext: { langKey },
  data: {
    allMdx: { edges: posts },
  },
}) => {
  const profileImage = useProfileImage()

  return (
    <Layout langKey={langKey}>
      <SEO title="przemuh.dev" lang={langKey} imageSrc={profileImage.src} />
      <section>
        <Container>
          <Row className="justify-content-center">
            {posts.map(({ node: post }) => (
              <PostClassic key={post.id} {...post} />
            ))}
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query blogIndex($langKey: String) {
    allMdx(
      filter: {
        fields: {
          sourceInstanceName: { eq: "blog" }
          langKey: { eq: $langKey }
        }
      }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 200)
          fields {
            slug
          }
          frontmatter {
            title
            date
            image {
              childImageSharp {
                fluid(maxWidth: 945, maxHeight: 390, cropFocus: ENTROPY) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
