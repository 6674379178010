import React from "react"
import { css } from "@emotion/core"
import Image from "gatsby-image"
import { Link } from "gatsby"

import { Row, Col } from "../grid"

import {
  PostImg,
  PostTitle,
  PostDescribe,
  PostVideo,
  ReadMore,
} from "./elements"
import VideoPlayer from "../videoPlayer"

const postImage = css`
  border-radius: 5px;
  margin-bottom: 40px;
`
const wrapper = css`
  margin-bottom: 70px;
`

const postTitle = css`
  margin-bottom: 20px;
`

const postVideo = css`
  margin-bottom: 40px;
  .plyr {
    border-radius: 5px;
  }
`

const PostClassic = ({ excerpt, frontmatter, fields: { slug } }) => {
  return (
    <Col xs={12} md={10}>
      <div css={wrapper}>
        {frontmatter.image && (
          <Link to={slug}>
            <PostImg css={postImage}>
              <Image fluid={frontmatter.image.childImageSharp.fluid} />
            </PostImg>
          </Link>
        )}

        {frontmatter.video && (
          <PostVideo css={postVideo}>
            <VideoPlayer {...frontmatter.video} title={frontmatter.title} />
          </PostVideo>
        )}

        <Row>
          <Col xs={12} md={10} className="mx-auto">
            <div>
              <PostTitle to={slug} css={postTitle}>
                {frontmatter.title}
              </PostTitle>

              {/*<PostCredit>*/}
              {/*  <PublicationDate>*/}
              {/*    {new Date(frontmatter.date).toLocaleDateString(lang, {*/}
              {/*      dateStyle: "medium",*/}
              {/*    })}*/}
              {/*  </PublicationDate>*/}
              {/*</PostCredit>*/}

              <PostDescribe>{excerpt}</PostDescribe>
              {/* <ReadMore to={slug} /> */}
            </div>
          </Col>
        </Row>
      </div>
    </Col>
  )
}

export default PostClassic

/*
<div class="post-block post-classic">
  <div class="post-img"><img src="assets/images/homepage1/post-1.png" alt="post image"></div>
  <div class="row">
    <div class="col-12 col-md-10 mx-auto">

      <div class="post-detail">
        <a class="post-title" href="blog_detail.html">North Dakota Learns From Norway</a>

        <div class="post-credit">
          <div class="author">
            <a class="author-avatar" href="#"><img src="assets/images/avatar/avatar-1.png" alt="auhtor"></a>
            <h5 class="author-name">Lucas Norman</h5>
          </div>
          <h5 class="upload-day">February 17, 2019</h5>
          <div class="post-tag"><a href="index.html">tech</a></div>
        </div>
        <p class="post-describe">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
      </div>
    </div>
  </div>
</div>
 */
